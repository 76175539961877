import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './RewardSignUp.css';
import { SERVER_URL } from '../../util/EnvironmentConfig';

function Reward() {
    // State to hold form data
    const [formData, setFormData] = useState({
      rewardName: '',
      rewardDescription: '',
      rewardTerms: '',
      pointsRequired: '',
      validDate: '',
      rewardImages: '', //A string for now, but will change to null when we change to png upload
      userEligibility: "bronze",
      methodOfDelivery: "email",
      creationDate: ''
      });

    
    // State used to display errors when image validations fail
    const [imageError, setImageError] = useState('');
    

    // Handle input changes
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    
    // Checks if inputted image meets the requirements
    const validateImage = (file) => {
      return new Promise((resolve, reject) => {
        // Check file size (16MB)
        const maxSize = 16 * 1024 * 1024;
        if (file.size > maxSize) {
          reject('Image size must be less than 16MB');
          return;
        }

        // Check file type
        if (!file.type.startsWith('image/png')) {
          reject('Only PNG images are allowed');
          return;
        }

        // Check dimensions
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          URL.revokeObjectURL(objectUrl);
          if (img.width > 400 || img.height > 500) {
            reject('Image dimensions must be 400x500 pixels or smaller');
          } else {
            resolve(file);
          }
        };

        img.onerror = () => {
          URL.revokeObjectURL(objectUrl);
          reject('Error loading image');
        };

        img.src = objectUrl;
      });
    };
    
    // Handle image changes
    const handleImageChange = async (e) => {
      const file = e.target.files[0];
      setImageError('');
      
      if (!file) return;

      try {
        // Check if the image meets the requirements
        await validateImage(file);

        // Convert image to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {

          setFormData(prevState => ({
            ...prevState,
            rewardImage: reader.result,
          }));

        }
        
      } catch (error) {
        setImageError(error);
        e.target.value = ''; // Reset file input
      }
    };

    // Handles form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {

        // Create a single reward object with all data
        const rewardData = {
          rewardName: formData.rewardName,
          rewardDescription: formData.rewardDescription,
          rewardTerms: formData.rewardTerms,
          pointsRequired: formData.pointsRequired,
          rewardImages: formData.rewardImages,
          validDate: formData.validDate,
          userEligibility: formData.userEligibility,
          methodOfDelivery: formData.methodOfDelivery,
          creationDate: formData.creationDate
        };

        const response = await axios.post(`${SERVER_URL}/reward/add-reward`, rewardData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        alert('You have successfully signed up your reward!');
      } catch (error) {
        alert('Error creating reward. Please try again.');
      }
  };

    return (
      <div className="reward">
        <h1 style={{ color: 'white'}}>Create a reward</h1>
        <form onSubmit={handleSubmit}>

        {/*Text input box for the name of the reward*/}
        <div>
          <label htmlFor="rewardName" style={{ color: 'white' }}>Reward name:</label><br></br>
          <input
            id="rewardName"
            type="text"
            name="rewardName"
            value={formData.rewardName}
            onChange={handleChange}
            required
          />
        </div>

        {/*Text input box for the description of the reward*/}
        <div>
          <label htmlFor="rewardDescription" style={{ color: 'white' }}>Reward description:</label><br></br>
          <textarea
            id="rewardDescription"
            type="text"
            name="rewardDescription"
            value={formData.rewardDescription}
            onChange={handleChange}
            required
          />
        </div>

        {/*Text input box for reward terms and conditions */}
        <div>
          <label htmlFor="rewardTerms" style={{ color: 'white' }}>Reward terms and conditions:</label><br></br>
          <textarea
            id="rewardTerms"
            type="text"
            name="rewardTerms"
            value={formData.rewardTerms}
            onChange={handleChange}
            required
          />
        </div>

        {/*Input box for reward image */}
        <div>
          <label htmlFor="rewardImage" style={{ color: 'white' }}>Reward image (PNG only, max 16MB, max 400x500px):</label><br></br>
          <input
            id="rewardImage"
            type="file"
            accept="image/png"
            name="rewardImage"
            onChange={handleImageChange}
            required
          />
          { imageError && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              {imageError}
            </div>
          )}
        </div>

        {/*Input box for reward required number of points */}
        <div>
          <label htmlFor="pointsRequired" style={{ color: 'white' }}>Redeem points required:</label><br></br>
          <input
            id="pointsRequired"
            type="number"
            min="1"
            name="pointsRequired"
            value={formData.pointsRequired}
            onChange={handleChange}
            required
          />
        </div>
        
        {/*Input box for reward date validity */}
        <div>
          <label htmlFor="validDate" style={{ color: 'white' }}>Final date this reward will be valid:</label><br></br>
          <input
            id="validDate"
            type="date"
            name="validDate"
            value={formData.validDate}
            onChange={handleChange}
            required
          />
        </div>

        {/*Drop down box to indicate user eligibility*/}
        <div>
          <label htmlFor="userEligibility" style={{ color: 'white'}}>Lowest rank eligible for this reward:</label><br></br>
            <select
              id="userEligibility"
              name="userEligibility"
              value={formData.userEligibility}
              onChange={handleChange}
              required
            >
              <option value="bronze">Bronze</option>
              <option value="silver">Silver</option>
              <option value="gold">Gold</option>
              <option value="platinum">Platinum</option>
              <option value="diamond">Diamond</option>
              <option value="S-tier">S-tier</option>
            </select>
        </div>        

        {/*Drop down box to indicate delivery method of the reward*/}
        <div>
          <label htmlFor="methodOfDelivery" style={{ color: 'white'}}>Delivery method:</label><br></br>
            <select
              id="methodOfDelivery"
              name="methodOfDelivery"
              value={formData.methodOfDelivery}
              onChange={handleChange}
              required
            >
              <option value="email">E-mail</option>
            </select>
        </div>

        <button type="submit">Create</button>
        </form>
      </div>
    );

}

export default Reward;