import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';

import { jwtDecode } from 'jwt-decode';
import Footer from './components/nav/Footer';
import Header from './components/nav/Header';
import RequireAuth from './components/RequireAuth';
import Unauthorized from './components/Unauthorized';
import { AuthenticationContext } from './context/Authentication';
import { AuthProvider } from './context/AuthProvider';
import About from './pages/About';
import AdvanceSearchMovies from './pages/AdvanceSearchMovies';
import AdvanceSearchTv from './pages/AdvanceSearchTv';
import Search from './pages/AdvSearch';
import Login from './pages/auth/login/Login';
import Register from './pages/auth/register/Register';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Survey from './pages/survey/Survey';
import Terms from './pages/Terms';

// Rating: Education
// import EducationLanding from './pages/education/EducationLanding';
import EducationRating from './pages/education/EducationRating';
// Rate Political Policies
// Rating: Entertainement
import CollisionRating from './pages/CollisionRating';
import EntertainmentRating from './pages/entertainment/EntertainmentRating';
// Admin Stuff
import Sidebar from './pages/admin/components/sidebar/Sidebar';
import Topbar from './pages/admin/components/topbar/Topbar';
import AdminHome from './pages/admin/pages/home/Home';
import Ratings from './pages/admin/pages/ratings/Ratings';
import User from './pages/admin/pages/user/User';
import UserList from './pages/admin/pages/userList/UserList';

import EditQuestion from './pages/admin/pages/editQuestion/EditQuestion';
import NewQuestion from './pages/admin/pages/newQuestion/NewQuestion';
import Question from './pages/admin/pages/Question/Question';

import Category from './pages/admin/pages/Category/Category';
import EditCategory from './pages/admin/pages/editCategory/EditCategory';
import NewCategory from './pages/admin/pages/newCategory/NewCategory';

// Home Page
import Home from './pages/home/Home';

// Partners Page
import Partner from './pages/partners/Partner';

// Contest Page
import ErrorPage from './pages/error/ErrorPage';
import QRCodeHandler from './pages/QRCodeHandler';

// Business Sign-up Page
import BusinessSignUp from './pages/businessSignUp/BusinessSignUp'

// Dashboard
import Dashboard from './pages/Dashboard/Dashboard';

// Reward Sign-up Page
import RewardSignUp from './pages/reward/RewardSignUp'
// Points Marketplace
import Marketplace from './pages/marketplace/marketplace';

export const ROLES = {
  Admin: 1,
  Student: 2,
  Faculty: 3,
  User: 4,
};

function Menu() {
  return (
    <Routes>
      <Route path="*" element={<ErrorPage />} />
      <Route path="/" element={<Home />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/un" element={<Unauthorized />} />
      <Route path="/search" element={<Search />} />
      <Route
        path="/advance-search/movies"
        element={<AdvanceSearchMovies />}
      ></Route>
      <Route path="/advance-search/tv" element={<AdvanceSearchTv />}></Route>
      <Route path="/about" element={<About />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/partners" element={<Partner />} />
      <Route path="/qr-code/:code" element={<QRCodeHandler />} />
      <Route path="/BusinessSignUp" element={<BusinessSignUp />} />
      <Route path="/rewardSignUp" element={<RewardSignUp />} />
      <Route
        element={
          <RequireAuth
            allowedRoles={[
              ROLES.User,
              ROLES.Faculty,
              ROLES.Student,
              ROLES.Admin,
            ]}
          />
        }
      >
        <Route path="/rate/:cat/:id" element={<EntertainmentRating />} />
        <Route path="/rate/collision" element={<CollisionRating />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/marketplace" element={<Marketplace />} />
      </Route>
      <Route
        element={
          <RequireAuth
            allowedRoles={[ROLES.Faculty, ROLES.Student, ROLES.Admin]}
          />
        }
      >
        <Route path="/rate/:cat" element={<EducationRating />} />
      </Route>
      <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
        <Route path="admin" element={<AdminHome />} />
        <Route path="/admin/users" element={<UserList />} />
        <Route path="/admin/user/:id" element={<User />} />
        <Route path="/admin/ratings" element={<Ratings />} />

        <Route path="/admin/questions" element={<Question />} />
        <Route path="/admin/edit-questions" element={<EditQuestion />} />
        <Route path="/admin/newQuestion" element={<NewQuestion />} />

        <Route path="/admin/category" element={<Category />} />
        <Route path="/admin/update-category" element={<EditCategory />} />
        <Route path="/admin/newcategory" element={<NewCategory />} />
        <Route path="/survey" element={<Survey />} />
      </Route>
    </Routes>
  );
}

function App(props) {
  let data = '';
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const login = () => {
    setIsLoggedIn(true);
  };
  const logout = () => {
    setIsLoggedIn(false);
  };

  if (localStorage.getItem('loginToken') && isLoggedIn === false) {
    data = jwtDecode(localStorage.getItem('loginToken'));
    setIsLoggedIn(true);
  } else if (localStorage.getItem('loginToken')) {
    data = jwtDecode(localStorage.getItem('loginToken'));
  }

  return (
    <AuthenticationContext.Provider value={{ isLoggedIn, login, logout }}>
      <AuthProvider>
        {window.location.pathname.includes('admin') &&
        data?.role === ROLES.Admin &&
        data?.role !== undefined ? (
          <>
            <Topbar />
            <div className="container-admin">
              <Sidebar />
              <Menu />
            </div>
          </>
        ) : (
          <div className="main-app">
            <Header />
            <Menu />
            <Footer />
          </div>
        )}
      </AuthProvider>
    </AuthenticationContext.Provider>
  );
}

export default App;
