
import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import './Dashboard.css';
import Review from './Review';
import { SERVER_URL } from '../../util/EnvironmentConfig';
import axios from 'axios';


const token = localStorage.getItem('loginToken');
const userData = token ? jwtDecode(token) : '';

// Custom ProgressBar component
const ProgressBar = ({ label, value, max }) => {
    return (
        <div className="progress-bar">
            <label>{label}</label>
            <progress value={value} max={max}></progress>
            <span>{Math.round((value / max) * 100)}%</span>
        </div>
    );
};

const Dashboard = () => {

    const server_url = process.env.REACT_APP_API_URI;
    const [userPoints, setUserPoints] = useState(null); // Initialize to null or 0 to avoid preset display
    const [pointHistory, setPointHistory] = useState([]); // Initialize as an empty array
    const [badgeProgress, setBadgeProgress] = useState([]); // Initialize as an empty array



    // Uncomment this when backend functions are available

    useEffect(() => {
        // Backend function to fetch the user's total points
        // e.g., backend API endpoint: GET /api/user/points
        const fetchUserPoints = async () => {
            const response = await axios.post(
                `${server_url}/user/points-balance`,
                {
                    userId: userData.id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
            setUserPoints(response.data.points); // Set total points for the user
        }
        fetchUserPoints();

        // Backend function to fetch the user's point history
        // e.g., backend API endpoint: GET /api/user/point-history
        const fetchPointHistory = async () => {
            const response = await axios.post(
                `${server_url}/user/points-balance-history`,
                {
                    userId: userData.id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
            setPointHistory(response.data.pointsHistory);
        };

        // Backend function to fetch the user's badge progress
        // POST /user/badges
        const fetchBadges = async () => {
            try {
                const url = `${SERVER_URL}/user/badges`;
                const response = await axios
                    .post(url,
                        {
                            'userId': userData.id
                        },
                        {
                            headers: { 'Content-Type': 'application/json' }
                        }
                    );
                setBadgeProgress(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        // Call the above functions to fetch the data
        fetchUserPoints();
        fetchPointHistory();
        fetchBadges();
    }, [userData.id, server_url]);


    return (
        <div className="dashboard">
            <div className="dashboard-title">Dashboard</div>

            <div className="points-section">
                {/* Display the total points from the backend */}
                <h2>Total Points: <span>{userPoints}</span></h2>
                <button className="redeem-button" onClick={() => {
                    window.location.href = '/marketplace';
                }}>
                    Redeem Points
                </button>
            </div>

            {/* Point History Section */}
            <div className="point-history-section">
                <h3>Point History</h3>
                <ol>
                    {pointHistory.length > 0 ?
                        pointHistory.sort((a, b) => b.timestamp - a.timestamp).slice(0, 5).map((x, index) => (
                            <li key={index}>
                                <div className="point-entry">
                                    <span className="activity">{x.activity}</span>
                                    <span className="date">{new Date(x.timestamp).toLocaleDateString("en-US")}</span>
                                    <span className={x.points >= 0 ? "points-positive" : "points-negative"}>
                                        {x.points >= 0 ? `+${x.points} pts` : `${x.points} pts`}
                                    </span>
                                </div>
                            </li>
                        )) :
                        <li>
                            <div className="point-entry">
                                <span className="activity">No recorded history</span>
                            </div>
                        </li>
                    }
                </ol>
            </div>

            {/* Family Badge Progress Section */}
            <div className="earn-badges-section">
                <h3>Family Badge Progress</h3>
                {badgeProgress.map((badge, index) => (
                    <ProgressBar key={index} label={badge.name} value={badge.progress} max={100} />
                ))}
            </div>

            <Review /> { }
        </div>
    );
};

export default Dashboard;

