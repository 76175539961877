import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './BusinessSignUp.css';
import { SERVER_URL } from '../../util/EnvironmentConfig';

function Signup() {
    // State to hold form data
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      website: '',
      logo: null,
      contactMethod: '',
      contactTime: '',
      category: [],
      marketLocation: [],
      marketSize: 'small',
      timezone: 'Etc/GMT+12',
      password: '',
      confirmPassword: ''
      });

    // States for password validation and errors
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
  
    // State to track registration errors
    const [registrationError, setRegistrationError] = useState('');

    // State used to display errors when image validations fail
    const [imageError, setImageError] = useState('');

    // Checks if inputted image meets the requirements
    const validateImage = (file) => {
      return new Promise((resolve, reject) => {
        // Check file size (16MB)
        const maxSize = 16 * 1024 * 1024;
        if (file.size > maxSize) {
          reject('Image size must be less than 16MB');
          return;
        }

        // Check file type
        if (!file.type.startsWith('image/png')) {
          reject('Only PNG images are allowed');
          return;
        }

        // Check dimensions
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          URL.revokeObjectURL(objectUrl);
          if (img.width > 200 || img.height > 200) {
            reject('Image dimensions must be 200x200 pixels or smaller');
          } else {
            resolve(file);
          }
        };

        img.onerror = () => {
          URL.revokeObjectURL(objectUrl);
          reject('Error loading image');
        };

        img.src = objectUrl;
      });
    };

    // Handle image changes
    const handleImageChange = async (e) => {
      const file = e.target.files[0];
      setImageError('');
      
      if (!file) return;

      try {
        // Checks if the image meets the requirements
        await validateImage(file);

        // Convert image to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setFormData(prevState => ({
            ...prevState,
            logo: reader.result // Store base64 string instead of file object
          }));
        };

      } catch (error) {
        setImageError(error);
        e.target.value = ''; // Reset file input
      }
    };

    // Handle input changes
    const handleChange = (e) => {
      const { name, value, options } = e.target;
      
      // Handle multi-select
      if (name === 'category' || name === 'marketLocation') {
        const selectedValues = Array.from(options)
          .filter(option => option.selected)
          .map(option => option.value);
        
        setFormData(prevState => ({
          ...prevState,
          [name]: selectedValues
        }));
      
      } else {
        // Handle regular inputs
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));

        // Password validation
        if (name === 'password') {
          validatePassword(value);
        }
                
        // Confirm password validation
        if (name === 'confirmPassword' || (name === 'password' && formData.confirmPassword)) {
          validateConfirmPassword(name === 'password' ? value : formData.password, 
                                 name === 'confirmPassword' ? value : formData.confirmPassword);
        }
      }      
      
    };

    // Password validation function
    const validatePassword = (password) => {
      if (password.length < 8) {
        setPasswordError('Password must be at least 8 characters long');
      } else {
        setPasswordError('');
      }
    };

    // Confirm password validation function
    const validateConfirmPassword = (password, confirmPass) => {
      if (password !== confirmPass) {
        setConfirmPasswordError('Passwords do not match');
      } else {
        setConfirmPasswordError('');
      }
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();

      // Check for password errors before submitting
      if (passwordError || confirmPasswordError || formData.password !== formData.confirmPassword) {
        alert('Please fix password errors before submitting');
        return;
      }

      // Remove confirmPassword before sending to backend
      const submitData = { ...formData };
      delete submitData.confirmPassword;

      // Send form data to backend
      axios.post(`${SERVER_URL}/business/businessRegistration`, submitData)
        .then(response => {
          alert('Thank you for signing up with KnowQuest! We are reviewing your application and will send you an email in 2-3 business days.');
          setRegistrationError(''); // Clear any previous errors
        })
        .catch(error => {
          // Handle different types of errors
          if (error.response && error.response.data.websiteExists) {
            // Specific handling for website already existing
            setRegistrationError('This website is already registered. Please use a different website.');
            alert('This website is already registered. Please use a different website.');
          } else {
            // Generic error handling
            setRegistrationError('There was an error signing up. Please try again later.');
            alert('There was an error signing up! Please try again later.');
          }
          setRegistrationError('');
        });
    };
  
    return (
      <div className="signup">
        <h1>Create a business account</h1>
        <form onSubmit={handleSubmit}>

          <div>
            <label htmlFor="name" style={{ color: 'white' }}>Business name:</label><br></br>
            <input
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label htmlFor="email" style={{ color: 'white'}}>Contact persons e-mail:</label><br></br>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div>
          <label htmlFor="website" style={{ color: 'white'}}>Website URL:</label><br></br>
            <input
              id="website"
              type="url"
              name="website"
              value={formData.website}
              onChange={handleChange}
              required
            />
          </div>

          <div>
          <label htmlFor="logo" style={{ color: 'white'}}>Company logo (PNG only, max 16MB, max 200x200px):</label><br></br>
            <input
              id="logo"
              type="file"
              accept="image/png"
              name="logo"
              onChange={handleImageChange}
              required
            />
            { imageError && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              {imageError}
            </div>
          )}
          </div>

          <div>
          <label htmlFor="category" style={{ color: 'white'}}>Appropriate category (Hold Ctrl/Cmd and click on your options to choose multiple):</label><br></br>
            <select
              id="category"
              name="category"
              multiple
              value={formData.category}
              onChange={handleChange}
              required
            >
              <optgroup label="Live">
                <option value="bank">Banking</option>
                <option value="insurance">Insurance</option>
                <option value="medicine">Medicine</option>
                <option value="healthcare">Healthcare</option>
                <option value="cpg">CPG</option>
                <option value="va">VA</option>
              </optgroup>

              <optgroup label="Learn">
                <option value="higher_ed">Higher education</option>
                <option value="e_learn">E-learning</option>
              </optgroup>

              <optgroup label="Watch">
                <option value="tv">Television</option>
                <option value="cinema">Cinema</option>
                <option value="streaming">Streaming</option>
                <option value="concerts">Concerts</option>
                <option value="theater">Theater</option>
                <option value="movies">Movies</option>
              </optgroup>

              <optgroup label="Eat">
                <option value="restaurants">Restaurants</option>
                <option value="take_out">Take-out</option>
                <option value="delivery">Delivery</option>
                <option value="delivered_food">Delivered food</option>
                <option value="beverages">Beverages</option>
                <option value="alcohol">Alcohol</option>
              </optgroup>

              <optgroup label="Play">
                <option value="video_games">Video games</option>
                <option value="consoles">Consoles</option>
                <option value="pre_built_pcs">Pre-built PCs</option>
              </optgroup>


            </select>
          </div>

          <div>
          <label htmlFor="marketLocation" style={{ color: 'white'}}>Market location (Hold Ctrl/Cmd and click on your options to choose multiple):</label><br></br>
            <select
              id="marketLocation"
              name="marketLocation"
              multiple
              value={formData.marketLocation}
              onChange={handleChange}
              required
            >
              <option value="north_america">North America (NA)</option>
              <option value="south_america">South America (SA)</option>
              <option value="europe">European Union (EU)</option>
              <option value="asia">Asia</option>
              <option value="other">Other</option>
            </select>
          </div>
          
          <div>
          <label htmlFor="marketSize" style={{ color: 'white'}}>Appropriate market size:</label><br></br>
            <select
              id="marketSize"
              name="marketSize"
              value={formData.marketSize}
              onChange={handleChange}
              required
            >
              <option value="small">Small (1 - 99)</option>
              <option value="medium">Medium (100 - 249)</option>
              <option value="large">Large (250 - 499)</option>
              <option value="enterprise">Enterprise (500+)</option>
            </select>
          </div>

          <div>
          <label htmlFor="timezone" style={{ color: 'white'}}>Timezone:</label><br></br>
            <select
              id="timezone"
              name="timezone"
              value={formData.timezone}
              onChange={handleChange}
              required
            >
              <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
              <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
              <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
              <option value="US/Alaska">(GMT-09:00) Alaska</option>
              <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
              <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
              <option value="US/Arizona">(GMT-07:00) Arizona</option>
              <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
              <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
              <option value="America/Managua">(GMT-06:00) Central America</option>
              <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
              <option value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
              <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
              <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
              <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
              <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
              <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
              <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
              <option value="America/Manaus">(GMT-04:00) Manaus</option>
              <option value="America/Santiago">(GMT-04:00) Santiago</option>
              <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
              <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
              <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
              <option value="America/Godthab">(GMT-03:00) Greenland</option>
              <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
              <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
              <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
              <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
              <option value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
              <option value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
              <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
              <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
              <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
              <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
              <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
              <option value="Asia/Amman">(GMT+02:00) Amman</option>
              <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
              <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
              <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
              <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
              <option value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
              <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
              <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
              <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
              <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
              <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
              <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
              <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
              <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
              <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
              <option value="Asia/Baku">(GMT+04:00) Baku</option>
              <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
              <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
              <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
              <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
              <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
              <option value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
              <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
              <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
              <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
              <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
              <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
              <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
              <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
              <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
              <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
              <option value="Australia/Perth">(GMT+08:00) Perth</option>
              <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
              <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
              <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
              <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
              <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
              <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
              <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
              <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
              <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
              <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
              <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
              <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
              <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
              <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
              <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
            </select>
          </div>

          <div>
            <label htmlFor="contactMethod" style={{ color: 'white'}}>Preferred method of contact:</label><br></br>
            <input
              id="contactMethod"
              type="text"
              name="contactMethod"
              value={formData.contactMethod}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label htmlFor="contactTime" style={{ color: 'white'}}>Preferred time of contact:</label><br></br>
            <input
              id="contactTime"
              type="text"
              name="contactTime"
              value={formData.contactTime}
              onChange={handleChange}
              required
            />
          </div>
          
          <div>
            <label htmlFor="password" style={{ color: 'white'}}>Password:</label><br></br>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            {passwordError && (
              <span className="error-message">{passwordError}</span>
            )}
          </div>

          <div>
          <label htmlFor="confirmPassword" style={{ color: 'white'}}>Confirm password:</label><br></br>
            <input
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            {confirmPasswordError && (
              <span className="error-message">{confirmPasswordError}</span>
            )}

          </div>


          <button 
          type="submit"
          disabled={!!passwordError || !!confirmPasswordError}
          >Sign Up</button>
        </form>
      </div>
    );
}
  
export default Signup;