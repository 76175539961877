import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { SERVER_URL } from '../../util/EnvironmentConfig';
import axios from 'axios';
import Modal from '@mui/material/Modal';

const token = localStorage.getItem('loginToken');
const userData = token ? jwtDecode(token) : '';

const placeholder = {
    id: 0,
    name: 'Default',
    category: 'Default',
    points: 10,
    image: 'https://badergruppe.com/wp-content/uploads/2021/11/placeholder-800x800.png',
    description: 'Default',
    terms: 'Default',
    delivery: 'Default',
    dateAvailable: '0000-00-00T23',
    validity: '2099-12-31-T23',
};

const Marketplace = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(placeholder);
    const [userPoints, setUserPoints] = useState(0);
    const [prodPopUp, setProdPopUp] = useState(false);
    const [redPopUp, setRedPopUp] = useState(false);

    // Function to fetch products
    const fetchProducts = async () => {
        try {
            const response = await axios.post(
                `${SERVER_URL}/reward/get-rewards`,
                {
                    userId: userData.id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const formattedData = response.data.products.map((item) => ({
                id: item._id,
                name: item.rewardName,
                category: item.category,
                points: item.pointsRequired,
                image: item.rewardImages,
                description: item.rewardDescription,
                terms: item.rewardTerms,
                delivery: item.methodOfDelivery,
                dateAvailable: item.Date,
                validity: item.validity,
            }));

            setProducts(formattedData);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    // Function to fetch the user's total points
    const fetchUserPoints = async () => {
        try {
            const response = await axios.post(
                `${SERVER_URL}/user/points-balance`,
                {
                    userId: userData.id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            setUserPoints(response.data.points);
        } catch (error) {
            console.error('Error fetching user points:', error);
        }
    };

    useEffect(() => {
        fetchProducts();
        fetchUserPoints();
    }, []);

    const handleOpen = () => {
        setProdPopUp(true);
    }

    const handleClose = () => {
        setProdPopUp(false);
    }

    const handleItemClick = (product) => {
        // Set the selected product for the pop-up
        setSelectedProduct(product); 
        handleOpen();
    }
    
    const redeemOffer = async (selectedProduct) => {

        try {
            const awardPointsUrl = `${SERVER_URL}/user/update-points-balance`;
            await axios.post(awardPointsUrl, {
                userId: userData.id,
                points: selectedProduct.points * -1,
                activity: "Redeem " + selectedProduct.name,
            });

        } catch (error) {
            console.error('Error removing user points:', error);
        }

        handleClose();
        setRedPopUp(true);

        setTimeout(() => {
            setRedPopUp(false);
        }, 1500)

        fetchUserPoints();
    }
    ;

    return (
        <div className="min-h-screen bg-slate-900 p-8">
            <div className="max-w-6xl mx-auto">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-2xl font-bold text-white">Redeem Points</h1>
                    <div className="bg-violet-500 px-4 py-2 rounded-lg">
                        <span className="text-white font-bold">{userPoints} Points</span>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                    {products.map((product) => (
                        <div
                            key={product.id}
                            className="relative group"
                            onClick={() => handleItemClick(product)}
                        >
                            <div className="bg-gradient-to-b from-slate-100 to-slate-200 rounded-lg overflow-hidden">
                                <div className="aspect-w-4 aspect-h-5 relative">
                                    <img
                                        src={product.image}
                                        className="object-cover w-full h-full"
                                    />
                                    <div className="absolute top-4 left-4 right-4 flex justify-between items-center">
                                        <span className="bg-white/90 backdrop-blur px-3 py-1 rounded-lg text-sm">
                                            {product.points.toFixed(0)}
                                        </span>
                                    </div>
                                </div>
                                <div className="p-4">
                                    <h3 className="font-medium text-slate-900">{product.name}</h3>
                                    <p className="text-sm text-slate-600">{product.category}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Product Pop-up Modal */}

            <Modal open={prodPopUp} onClose={() => setProdPopUp(false)}>
                <div className="fixed inset-0 flex justify-center items-center">
                    <div className="relative w-[800px] h-[500px] bg-[#0e1834] rounded-lg overflow-hidden">
                        <button onClick={() => setProdPopUp(false)} className="absolute right-0 bg-transparent border-transparent">
                            X
                        </button>
                        <div className="p-4">
                            <h3 className="text-center font-bold text-2xl text-[#FFF]">
                                {selectedProduct.name}
                            </h3>
                            <p className="text-center text-[#E3E9F0]">
                                {selectedProduct.category}
                            </p>
                            <br></br>
                            <div className="flex pl-4 pr-4">
                                <div className="h-[310px] w-[248px] relative">
                                    <img
                                        src={selectedProduct.image}
                                        className="object-cover rounded-lg w-full h-full"
                                    />
                                    <div className="absolute top-4 left-4 right-4 flex justify-between items-center">
                                        <span className="bg-white/90 backdrop-blur px-3 py-1 rounded-lg text-sm">
                                            {selectedProduct.points.toFixed(0)}
                                        </span>
                                    </div>
                                </div>
                                <div className="overflow-y-auto ml-auto mr-0 w-[460px] h-[310px] pr-[10px]">
                                    <div className="ml-0 mr-auto text-left">
                                        <h4 className="font-bold text-white">
                                            Description:
                                        </h4>
                                        <p className="text-justify text-sm text-white">
                                            {selectedProduct.description}
                                        </p>
                                        <br></br>
                                        <h4 className="font-bold text-white">
                                            Terms and conditions:
                                        </h4>
                                        <p className="text-justify text-sm text-white">
                                            {selectedProduct.terms}
                                        </p>
                                        <br></br>
                                        <h4 className="font-bold text-white">
                                            Method of delivery:
                                        </h4>
                                        <p className="text-justify text-sm text-white">
                                            {selectedProduct.delivery}
                                        </p>
                                        <br></br>
                                        <h4 className="font-bold text-white">
                                            Available until:
                                        </h4>
                                        <p className="text-justify text-sm text-white">
                                            {selectedProduct.validity.split("T")[0]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center">
                                <button className="disabled:bg-[#49454F] bg-[#F2994A] group-[hover:not(:disabled)]:bg-[#FF9933]" onClick={async () => await redeemOffer(selectedProduct)} disabled={selectedProduct.points > userPoints}>
                                    Redeem
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* Redeem Pop-up Modal */}

            <Modal open={redPopUp} disableScrollLock hideBackdrop disableEnforceFocus style={{position:'initial'}}>
                <div className="fixed rounded-lg bottom-0 right-0 w-[360px] h-[80px] bg-[#83F19E] p-4">
                    <h1>
                        Redeemed {selectedProduct.name}
                    </h1>
                    <p className="text-center text-[#49454F] text-sm">
                        Thanks for your redemption
                    </p>
                </div>  
            </Modal>
        </div>
    );
};

export default Marketplace;



