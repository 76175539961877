// Review.js
import React, { useState, useEffect } from 'react';
import './Review.css';
import axios from 'axios';
import { SERVER_URL } from '../../util/EnvironmentConfig';
import { jwtDecode } from 'jwt-decode';

const token = localStorage.getItem('loginToken');
const userData = token ? jwtDecode(token) : '';

const Review = () => {
    // State to hold reviews fetched from the backend
    const [reviews, setReviews] = useState([
        { id: 1, family: "Eat", category: "Delivery", pointsEarned: 85 },
        { id: 2, family: "Play", category: "Video games", pointsEarned: 92 },
        { id: 3, family: "Learn", category: "Education", pointsEarned: 78 }
    ]); // Placeholder: preset reviews
    const [referralLink, setReferralLink] = useState('https://yourapp.com/referral?code=12345'); // Placeholder: example referral link

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink);
        alert("Referral link copied to clipboard!");
    };

    // Uncomment this when backend functions are available
    
    useEffect(() => {
        // Backend function to fetch recent reviews
        // e.g., backend API endpoint: POST /user/reviews
        const fetchReviews = async () => {
            try {
              const url = `${SERVER_URL}/user/reviews`;
              const response = await axios
                .post(url, 
                    {
                      'userId': userData.id
                    },
                    {
                      headers: { 'Content-Type': 'application/json' }
                    }
                );
                setReviews(response.data);
            } catch (error) {
              console.log(error);
            }
          };

        // Backend function to fetch referral link
        // e.g., backend API endpoint: GET /api/user/referral-link
        const fetchReferralLink = async () => {
            const response = await fetch('/api/user/referral-link');
            const data = await response.json();
            setReferralLink(data.link); // Set referral link for the user
        };

        // Call the above functions to fetch the data
        fetchReviews();
        //fetchReferralLink();
    }, []);
    

    return (
        <>
            {/* Recent Reviews Section */}
            <div className="reviews-section">
                <h3>Recent Reviews</h3>
                {reviews.length > 0 ?
                reviews.map((review, index) => (
                    <div key={index} className="review">
                        <p>Review: {review.contentName}</p>
                        <p>Family: {review.family}</p>
                        <p>Category: {review.category}</p>
                        <p>Points Earned: {review.pointsEarned}</p>
                    </div>
                )) :
                    <div className="review">
                        <p>No recorded reviews</p>
                    </div>
                }
            </div>

            {/* Refer a Friend Section */}
            <div className="refer-friend-section">
                <h3>Refer a Friend!</h3>
                <p>Share this link with a friend to refer them:</p>
                <input
                    type="text"
                    value={referralLink}
                    readOnly
                    className="referral-link"
                />
                <button onClick={copyToClipboard}>Copy Link</button>
            </div>
        </>
    );
};

export default Review;


